import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './ProductItemV1.styles';
import { formatPrice } from '../../utils/formatPrice';
import { ProductPrice } from '../../utils/productPrice';
import { useCount } from '../../context/CountContext';

const ProductItemV1 = () => {
  const { state, dispatch } = useCount();
  const { repurchaseQuantity: quantity } = state;
  const {
    onetime,
    subscription,
    currentCategory,
    currentProduct,
    currency
  } = useContext(FunnelContext);

  const discountedPrice = ProductPrice({
    type: `discounted_price`,
    currentCategory: currentCategory,
    currentProduct: currentProduct
  });
  const isSubscription = currentCategory === 'subscriptions';
  const { products } = isSubscription ? subscription : onetime;
  const product = products.find((el, i) =>
    isSubscription
      ? currentProduct === `subscription_${i}`
      : currentProduct === `onetime_${i}`
  );
  const priceSettings = { currencySymbol: currency?.symbol || '$' };

  return (
    <S.Container>
      <S.ProductWrapper>
        <S.ProductImage>
          {product?.checkoutData?.image && (
            <img src={product.checkoutData.image} alt={product.display_title} />
          )}
          <S.QuantityCircle>{quantity}</S.QuantityCircle>
        </S.ProductImage>
        <S.ProductInfo>
          <S.ProductName>{product.display_title}</S.ProductName>
          <S.Quantity>
            <S.Decrement
              onClick={() => {
                quantity > 1 && dispatch({ type: 'decrement' });
              }}
            >
              -
            </S.Decrement>
            <S.QuantityNumber>{quantity}</S.QuantityNumber>
            <S.Increment
              onClick={() => {
                quantity < 20 && dispatch({ type: 'increment' });
              }}
            >
              +
            </S.Increment>
          </S.Quantity>
        </S.ProductInfo>
      </S.ProductWrapper>
      <S.Price>
        {formatPrice(discountedPrice * quantity, priceSettings)}
      </S.Price>
    </S.Container>
  );
};

export default ProductItemV1;
